import {
  LEADER_BOARD_DATE_PERIODS,
  LeaderBoardFilters,
  LeaderBoardPeriod,
  LeaderBoardUser,
} from "../../../api/LeaderBoardApi";
import { PaginationConfig } from "../../../components/Pagination";
import { LeaderBoardTableRow } from "../components/LeaderBoardTable";

export const mapLeaderBoardUsersToTableRows = (
  recipientsLeaderBoard: LeaderBoardUser[],
  pagination: PaginationConfig
): LeaderBoardTableRow[] => {
  const { page, limit } = pagination;
  const rankOffset = (page - 1) * limit;

  const rows: LeaderBoardTableRow[] = recipientsLeaderBoard.map(
    (recipient, index) => {
      return {
        key: index,
        rank: recipient?.rank ?? index + rankOffset + 1, // TODO: Remove the fallback and rely only on the rank provided by the API
        name: recipient.name,
        score: recipient.score,
        providerUserId: recipient.providerUserId,
      };
    }
  );

  return rows;
};

export const buildLeaderBoardFilters = (
  teamId: number | null,
  period: LeaderBoardPeriod
): LeaderBoardFilters => {
  let filters: LeaderBoardFilters = {
    fromDate: LEADER_BOARD_DATE_PERIODS[period].fromDate,
    toDate: LEADER_BOARD_DATE_PERIODS[period].toDate,
  };

  if (teamId) {
    filters = {
      ...filters,
      teamId,
    };
  }

  return filters;
};
