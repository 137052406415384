import { FC, useEffect } from "react";
import {
  Field,
  Select,
  Switch,
  Title3,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTitle,
  makeStyles,
  shorthands,
  tokens,
} from "@fluentui/react-components";
import { Resolver, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";

import { User, UserApi } from "../../../../../api/UserApi";
import { Alert } from "../../../../../components/Alert";
import { previewDateFormat, previewTimeFormat } from "../utils/helpers";
import Button from "../../../../../components/Button";
import { QueryKey } from "../../../../../constants/api";
import { UpdateUserConfigurationFormValidationSchema } from "../utils/validationSchema";
import {
  ConfigurationDateFormat,
  ConfigurationTimeFormat,
  DEFAULT_USER_CONFIGURATION,
} from "../constants";
import { useStoreContext } from "../../../../../context/StoreContext";
import { EmojiSadRegular } from "@fluentui/react-icons";

export interface UpdateUserConfigurationForm {
  dateFormat: string;
  timeFormat: string;
  notifications: {
    weeklyReminder: boolean;
    weeklySummary: boolean;
    monthlySummary: boolean;
    yearlySummary: boolean;
    achievements: boolean;
  };
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap(tokens.spacingVerticalL),
  },
  formFieldError: {
    backgroundColor: tokens.colorStatusDangerBackground1,
    ...shorthands.border("1", "solid", tokens.colorStatusDangerBorder1),
  },
  formFieldErrorMessage: {
    color: tokens.colorStatusDangerForeground1,
  },
});

const TOAST_TITLE = [
  "But why?",
  "Oh no!",
  "Oops!",
  "Oh dear!",
  "What happened?",
  "Oh snap!",
  "Wait, what?",
];

export const getRandomToastTitle = () =>
  TOAST_TITLE[Math.floor(Math.random() * TOAST_TITLE.length)];

const TOAST_MESSAGE = [
  "We don't spam, we promise!",
  "I'm not sure if that's a good idea, we don't spam!",
  "We only send the good stuff!",
  "But, this way you'll miss out on the good stuff!",
  "We only send the important stuff!",
  "This way you risk missing out important updates!",
  "We only send the best stuff!",
  "We only send the relevant stuff!",
  "Such a shame, we only send good news your way!",
  "We only send the necessary stuff!",
  "Our goal is to keep you informed, not spam you!",
];

export const getRandomToastMessage = () =>
  TOAST_MESSAGE[Math.floor(Math.random() * TOAST_MESSAGE.length)];

const TOAST_FOOTER = [
  "Consider flipping the switch back on.",
  "You can always turn it back on...",
  "I hope you know what you're doing!",
  "You can always change your mind...",
  "I hope you're sure about this!",
  "You can always change your settings.",
  "You can alway reconsider your decision...",
];

export const getRandomToastFooter = () =>
  TOAST_FOOTER[Math.floor(Math.random() * TOAST_FOOTER.length)];

export interface UserConfigurationFormProps {
  user: User;
  onSuccess: () => void;
}

const UserConfigurationForm: FC<UserConfigurationFormProps> = ({
  user,
  onSuccess,
}) => {
  const styles = useStyles();
  const { dispatchToast } = useStoreContext();

  const { register, watch, setValue, handleSubmit } =
    useForm<UpdateUserConfigurationForm>({
      resolver: yupResolver(
        UpdateUserConfigurationFormValidationSchema
      ) as Resolver<UpdateUserConfigurationForm>,
      defaultValues: {
        dateFormat:
          user?.configurations?.settings?.dateFormat ??
          DEFAULT_USER_CONFIGURATION.dateFormat,
        timeFormat:
          user?.configurations?.settings?.timeFormat ??
          DEFAULT_USER_CONFIGURATION.timeFormat,
        notifications: {
          weeklyReminder:
            user?.configurations?.settings?.notifications?.weeklyReminder ??
            DEFAULT_USER_CONFIGURATION.notifications.weeklyReminder,
          weeklySummary:
            user?.configurations?.settings?.notifications?.weeklySummary ??
            DEFAULT_USER_CONFIGURATION.notifications.weeklySummary,
          monthlySummary:
            user?.configurations?.settings?.notifications?.monthlySummary ??
            DEFAULT_USER_CONFIGURATION.notifications.monthlySummary,
          yearlySummary:
            user?.configurations?.settings?.notifications?.yearlySummary ??
            DEFAULT_USER_CONFIGURATION.notifications.yearlySummary,
          achievements:
            user?.configurations?.settings?.notifications?.achievements ??
            DEFAULT_USER_CONFIGURATION.notifications.achievements,
        },
      },
    });

  const values = watch();

  const {
    mutateAsync: updateUserConfiguration,
    isPending: isUpdateUserConfigurationPending,
    isError,
    error,
  } = useMutation({
    mutationKey: [QueryKey.UpdateUserConfiguration],
    mutationFn: UserApi.updateUserConfiguration,
  });

  const onSubmit: SubmitHandler<UpdateUserConfigurationForm> = async (
    data: UpdateUserConfigurationForm
  ) => {
    try {
      const response = await updateUserConfiguration({
        providerUserId: user.providerUserId,
        ...data,
      });

      if (response?.id) {
        onSuccess();
      }
    } catch (exception: unknown) {
      const error = exception as Error;

      if (error.message) {
        console.error(error.message);
      }
    }
  };

  const showSadNotificationToast = () => {
    dispatchToast(
      <Toast>
        <ToastTitle
          media={<EmojiSadRegular />}
          data-testid="sad-notification-toast"
        >
          {getRandomToastTitle()}
        </ToastTitle>
        <ToastBody>{getRandomToastMessage()}</ToastBody>
        <ToastFooter>{getRandomToastFooter()}</ToastFooter>
      </Toast>,
      {
        intent: "error",
        timeout: 5000,
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className={styles.form}
      data-testid="settings--config-form"
    >
      <Field label="Select preferred date format" required>
        <Select
          {...register("dateFormat")}
          onChange={(_, data) => setValue("dateFormat", data.value)}
          data-testid="settings--config-date-format"
        >
          {Object.values(ConfigurationDateFormat)?.map((dateFormat) => (
            <option key={dateFormat} value={dateFormat}>
              {previewDateFormat(dateFormat)}
            </option>
          ))}
        </Select>
      </Field>

      <Field label="Select preferred time format" required>
        <Select
          {...register("timeFormat")}
          onChange={(_, data) => setValue("timeFormat", data.value)}
          data-testid="settings--config-time-format"
        >
          {Object.values(ConfigurationTimeFormat)?.map((timeFormat) => (
            <option key={timeFormat} value={timeFormat}>
              {previewTimeFormat(timeFormat)}
            </option>
          ))}
        </Select>
      </Field>

      <Title3>Notifications</Title3>
      <div>
        <Field label="Weekly Reminder">
          <Switch
            {...register("notifications.weeklyReminder")}
            checked={values.notifications.weeklyReminder}
            label={`Send me a weekly reminder to spread some joy by sending pizza to my team`}
            labelPosition="after"
            data-testid="settings--config-notifications-weekly-reminder"
            onChange={(event) => {
              const value = event.target.checked;
              setValue("notifications.weeklyReminder", value);

              if (!value) {
                showSadNotificationToast();
              }
            }}
          />
        </Field>

        <Field label="Weekly Summary">
          <Switch
            {...register("notifications.weeklySummary")}
            checked={values.notifications.weeklySummary}
            label={`Send me a recap for the activity of the team every week`}
            labelPosition="after"
            data-testid="settings--config-notifications-weekly-summary"
            onChange={(event) => {
              const value = event.target.checked;
              setValue("notifications.weeklySummary", value);

              if (!value) {
                showSadNotificationToast();
              }
            }}
          />
        </Field>

        <Field label="Monthly Recap">
          <Switch
            {...register("notifications.monthlySummary")}
            checked={values.notifications.monthlySummary}
            label={`Send me a report who sent the most pizzas every month`}
            labelPosition="after"
            data-testid="settings--config-notifications-monthly-summary"
            onChange={(event) => {
              const value = event.target.checked;
              setValue("notifications.monthlySummary", value);

              if (!value) {
                showSadNotificationToast();
              }
            }}
          />
        </Field>

        <Field label="End-of-Year Review">
          <Switch
            {...register("notifications.yearlySummary")}
            checked={values.notifications.yearlySummary}
            label={`Send me highlights of the year to celebrate our achievements`}
            labelPosition="after"
            data-testid="settings--config-notifications-yearly-summary"
            onChange={(event) => {
              const value = event.target.checked;
              setValue("notifications.yearlySummary", value);

              if (!value) {
                showSadNotificationToast();
              }
            }}
          />
        </Field>

        <Field label="Achievements">
          <Switch
            {...register("notifications.achievements")}
            checked={values.notifications.achievements}
            label={`Congratulate me when I unlock a new achievement`}
            labelPosition="after"
            data-testid="settings--config-notifications-achievements"
            onChange={(event) => {
              const value = event.target.checked;
              setValue("notifications.achievements", value);

              if (!value) {
                showSadNotificationToast();
              }
            }}
          />
        </Field>
      </div>

      {isError && error && (
        <Alert
          intent="error"
          title="Oh no, something went wrong!"
          message="We couldn't update the configurations. Please try again later."
        />
      )}

      <Button
        isLoading={isUpdateUserConfigurationPending}
        appearance="primary"
        type="submit"
      >
        Submit
      </Button>
    </form>
  );
};

export default UserConfigurationForm;
