import * as yup from "yup";
import { ConfigurationDateFormat, ConfigurationTimeFormat } from "../constants";
import { notifications } from "@microsoft/teams-js";

export const UpdateUserConfigurationFormValidationSchema = yup
  .object()
  .shape({
    dateFormat: yup
      .string()
      .required()
      .oneOf(Object.values(ConfigurationDateFormat), "Invalid date format"),
    timeFormat: yup
      .string()
      .required()
      .oneOf(Object.values(ConfigurationTimeFormat), "Invalid time format"),
    notifications: yup
      .object()
      .shape({
        weeklyReminder: yup.boolean().typeError("Invalid value provided"),
        weeklySummary: yup.boolean().typeError("Invalid value provided"),
        monthlySummary: yup.boolean().typeError("Invalid value provided"),
        yearlySummary: yup.boolean().typeError("Invalid value provided"),
        achievements: yup.boolean().typeError("Invalid value provided"),
      })
      .required(),
  })
  .required();
