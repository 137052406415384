import {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useState,
} from "react";
import { LeaderBoardPeriod } from "../../../api/LeaderBoardApi";

const LeaderBoardContext = createContext<{
  teamId: number;
  setTeamId: (value: number) => void;

  period: LeaderBoardPeriod;
  setPeriod: (value: LeaderBoardPeriod) => void;
}>({
  teamId: 0,
  setTeamId: () => {},

  period: LeaderBoardPeriod.AllTime,
  setPeriod: () => {},
});

interface LaderBoardContextProviderProps extends PropsWithChildren<{}> {}

export const LeaderBoardProvider: FC<LaderBoardContextProviderProps> = ({
  children,
}) => {
  const [teamId, setTeamId] = useState<number>(0);
  const [period, setPeriod] = useState<LeaderBoardPeriod>(
    LeaderBoardPeriod.AllTime
  );

  return (
    <LeaderBoardContext.Provider
      value={{
        teamId,
        setTeamId,

        period,
        setPeriod,
      }}
    >
      {children}
    </LeaderBoardContext.Provider>
  );
};

export const useLeaderBoardContext = () => {
  const context = useContext(LeaderBoardContext);

  if (!context) {
    throw new Error(
      "useLeaderBoardContext must be used within a LeaderBoardProvider"
    );
  }

  return context;
};
