import { PaginationConfig } from "../components/Pagination";
import { Api } from "./Api";

export interface LeaderBoardUser {
  id: number; // TODO: Maybe not used?
  name: string;
  score: number;
  providerUserId: string;
  rank?: number; // Maybe not optional anymore?
}

export enum LeaderBoardPeriod {
  AllTime = "all-time",
  LastYear = "last-year",
  LastMonth = "last-month",
  LastWeek = "last-week",
}

export const LEADER_BOARD_DATE_PERIODS: Record<
  LeaderBoardPeriod,
  { fromDate: Date; toDate: Date }
> = {
  [LeaderBoardPeriod.AllTime]: { fromDate: new Date(0), toDate: new Date() },
  [LeaderBoardPeriod.LastYear]: {
    fromDate: new Date(new Date().getFullYear() - 1, 0, 1),
    toDate: new Date(),
  },
  [LeaderBoardPeriod.LastMonth]: {
    fromDate: new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1),
    toDate: new Date(),
  },
  [LeaderBoardPeriod.LastWeek]: {
    fromDate: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate() - 7
    ),
    toDate: new Date(),
  },
};

export interface GetLeaderBoardRequestParams {
  filters: LeaderBoardFilters;
  pagination: PaginationConfig;
}

export interface LeaderBoardFilters {
  fromDate: Date;
  toDate: Date;
  teamId?: number;
}

export type GetLeaderBoardResponse = {
  data: LeaderBoardUser[];
  pagination: PaginationConfig;
};

export class LeaderBoardApi {
  public static API_ENDPOINT = `leaderboard`;

  public static async getLeaderBoardRecipients(
    params: GetLeaderBoardRequestParams
  ): Promise<GetLeaderBoardResponse> {
    const url = `${LeaderBoardApi.API_ENDPOINT}/recipients`;
    const queryParams = {
      ...params.filters,
      ...params.pagination,
    };

    const response = await Api.get(url, queryParams);

    return response;
  }

  public static async getLeaderBoardEndorsers(
    params: GetLeaderBoardRequestParams
  ): Promise<GetLeaderBoardResponse> {
    const url = `${LeaderBoardApi.API_ENDPOINT}/endorsers`;
    const queryParams = {
      ...params.filters,
      ...params.pagination,
    };

    const response = await Api.get(url, queryParams);

    return response;
  }
}
